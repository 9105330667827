import { api } from "@/util/api";
import { catApi, catVue } from "@/util/logging";
import { Expose, Type } from "class-transformer";
import { BaseType } from "./util/BaseType";

export class Kasse {
  @Expose() fid = 0;
  @Expose() kurz = "";
  @Expose() kasseID = 0;
}

export class KassenCheckModel extends BaseType {
  @Expose() uid = "";

  @Expose() date = "";
  @Expose()
  @Type(() => Kasse)
  kasse = new Kasse();

  @Expose() minRec = 0;
  @Expose() ctRec = 0;
  @Expose() maxRec = 0;
  @Expose() minDt = "";
  @Expose() maxDt = "";
  @Expose() ok = false;
  @Expose() missRec = new Array<string>();

  // === === === === === === === === === === === === === === ===

  static fetch = async (datum: string): Promise<KassenCheckModel[]> => {
    catApi.debug("fetch ... querying: ");
    try {
      const path = `v1/kassenscans/check/${datum}`;
      const response = await api.get<KassenCheckModel[]>(path);
      catApi.debug(`response: /${path} -> ${JSON.stringify(response)}`);

      if (response.status == 204) return [];
      const ret = BaseType.convertArray(KassenCheckModel, response.data);

      if (!Array.isArray(ret)) {
        catVue.error("Should have gotten a List, got nothing!", null);
        throw new Error("KassenCheckModel no list");
      }

      return ret;
      //
    } catch (e) {
      const err = new Error(JSON.stringify(e));
      catApi.error("caught Error: " + err.message, err);
      return Promise.reject(err);
    }
  };
}
