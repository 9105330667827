var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(" Kassencheck — Zeitraum von "+_vm._s(_vm.fmt(_vm.von))+" bis "+_vm._s(_vm.fmt(_vm.bis))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Datum")]),_vm._l((_vm.header),function(hdr){return _c('th',{key:hdr,staticClass:"text-right"},[_vm._v(" "+_vm._s(hdr)+" ")])})],2)]),_c('tbody',_vm._l((_vm.daten),function(date){return _c('tr',{key:date},[_c('td',[_vm._v(_vm._s(_vm.fmt(date)))]),_vm._l((_vm.header),function(hdr){return _c('td',{key:hdr,staticClass:"text-right text-body-2"},_vm._l((_vm.checks(date + '-' + hdr)),function(check){return _c('div',{key:check.uid,staticClass:"check-detail"},[_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(check.maxRec)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(check.maxDt))])])],1),_c('div',[(!check.ok)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"error--text",attrs:{"icon":"fa-regular fa-light-emergency-on"}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(check.missRec.join()))])]):_vm._e(),_vm._v(" "+_vm._s(check.ctRec)+" ↑ ")],1),_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(check.minRec)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(check.minDt))])])],1)])}),0)})],2)}),0)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }