
































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { catVue } from "@/util/logging";
import { KassenCheckModel } from "@/models/KassenCheckModel";
import store from "@/store";
import AuthModule from "@/store/modules/auth";
import Datum from "@/util/datum";

import { getModule } from "vuex-module-decorators";

// eslint-disable-next-line

const vrState = getModule(AuthModule, store);

// eslint-disable-next-line

@Component({})
export default class KassenCheck extends Vue {
  value = 0;
  daten = new Array<string>();
  header = new Array<string>();
  entries = new Map<string, KassenCheckModel[]>();
  isLoading = false;
  search = null;
  count = 0;

  @Prop() von = "";
  @Prop() bis = "";

  created(): void {
    catVue.info(`${this.$options.name}:: CREATED ... ${this.value}`);
  }

  mounted(): void {
    catVue.info(
      `${this.$options.name}:: MOUNTED ... ${this.von} .. ${this.bis}`
    );
    //       this.getRangeFromURL();

    vrState.filialenAktiv?.forEach((filiale) => {
      this.header.push(filiale.f_kurz);
      //         catVue.info(`aktiv: ${filiale.f_kurz}`);
    });
  }

  @Watch("von")
  @Watch("bis")
  getRangeFromURL(): void {
    //       catVue.info(        `${this.$options.name}:: watch -> ... ${this.von} .. ${this.bis}`       );

    const range = Datum.sort(this.von, this.bis);
    if (this.von !== range.start || this.bis !== range.end) {
      catVue.info(
        `${this.$options.name}:: strange -> ... ${this.von}<-${range.start} .. ${this.bis}<-${range.end}`
      );
      return;
    }

    const spread = Datum.spread(range);
    spread.forEach((date) => {
      if (!this.daten.includes(date)) {
        this.daten.push(date);
        this.searchChanged(date);
      }
    });

    this.daten.sort().reverse();
    //       catVue.info(`${this.$options.name}:: range -> ... ${this.daten}`);
  }

  fmt(datum: string): string {
    return Datum.datumDeutsch(datum);
  }

  checks(key: string): Array<KassenCheckModel> {
    const checks = this.entries.get(key);
    if (checks !== undefined) return checks;
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async searchChanged(date: string): Promise<boolean> {
    //       catVue.info(`${this.$options.name}:: lade -> ... ${date}`);
    KassenCheckModel.fetch(date)
      .then((items) => {
        //           catVue.info(`${this.$options.name}:: then -> ... ${items}`);
        items.forEach((check) => {
          //             catVue.info(`${this.$options.name}:: then -> ... ${check}`);
          const key = date + "-" + check.kasse.kurz;
          let val = this.entries.get(key);
          if (val === undefined) val = new Array<KassenCheckModel>();
          val.push(check);
          this.entries.set(key, val);
        });

        let i = this.daten.indexOf(date);
        this.$nextTick(() => this.daten.splice(i, 1, date));
      })
      .catch((e) => {
        catVue.error(`Fehler beim Laden der KassenCheckModel ... ${e}`, null);
      });
    return false;
  }
}
